import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DriverlistComponent } from './component/DriverMaintenance/driverlist/driverlist.component';
import { TrucklistComponent } from './component/TruckMaintenance/trucklist/trucklist.component';
import { DriverBlacklistComponent } from './component/driver-blacklist/driver-blacklist.component';
import { UnauthorizedComponent } from './component/unauthorized/unauthorized.component';
const routes: Routes = [
  { path: '', component: DriverlistComponent },
  { path: 'driver/list', component: DriverlistComponent },
  { path: 'truck/list', component: TrucklistComponent },
  { path: 'blacklist', component: DriverBlacklistComponent },
  { path: 'unauthorized', component: UnauthorizedComponent },
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
