import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/dev';
import { TruckOperationSiteTagging } from '../model/truckOperationTagging.model';
import { TruckTagginAndBusinessUnit } from '../model/truckTragginAndBusinessUnit.model';
import { Observable } from 'rxjs';
import { TokenService } from './token.service';

@Injectable({
  providedIn: 'root'
})
export class TruckOperationSiteTaggingService {
  baseApiUrl: string = environment.baseApiUrl

  constructor(private http: HttpClient, private tokenService: TokenService) { }


  getAllTruckOps() {
    return this.http.get<TruckOperationSiteTagging[]>(this.baseApiUrl + '/api/TruckOperationSiteTagging');
  }

  // getBusinessUnitAndTruckOps() {
  //   return this.http.get<TruckTagginAndBusinessUnit[]>(`${this.baseApiUrl}/api/GetAllBusinessUnits`);
  // }
  getBusinessUnitAndTruckOps(): Observable<TruckTagginAndBusinessUnit[]> {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.tokenService.getJwt()}`,
    });
    return this.http.get<TruckTagginAndBusinessUnit[]>(`${this.baseApiUrl}/api/GetAllBusinessUnits`, { headers })
  }

  getBusinessUnitAndTruckOpsLinq(truckId: string): Observable<TruckTagginAndBusinessUnit[]> {
    const token = this.tokenService.getJwt()
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`, // Retrieve token from your token service
    });
    return this.http.get<TruckTagginAndBusinessUnit[]>(`${this.baseApiUrl}/api/GetAllTruckOperationSiteTaggingWithBusinessUnit/${truckId}`, { headers });
  }
  addTruckOperationSiteTagging(truckOps: any[]) {
    return this.http.post(this.baseApiUrl + '/api/TruckOperationSiteTagging', truckOps);
  }

  updateOrAddTruckOperationSiteTagging(truckOps: any[]): Observable<any> {
    return this.http.post<any>(`${this.baseApiUrl}/api/UpdateOrAddTruckOperationSiteTagging`, truckOps)
  }

}
