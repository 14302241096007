<div class="container-fluid px-0">
    <div class="border border-bottom-0 p-2 bg-secondary-color py-3 header">
        <div class="d-flex justify-content-between align-items-center">
            <span class="fw-bold">Truck</span>
            <ul class="nav nav-underline">
                <li class="nav-item">
                    <a routerLink="/truck/form"
                        class="link-underline-opacity-0 link-underline text-secondary">Information</a>
                </li>
                <li class="nav-item">
                    <a routerLink="/truck/list" class="link-underline-opacity-0 link-underline text-secondary">List</a>
                </li>
            </ul>
        </div>
    </div>
</div>

<form [formGroup]="truckForm" (submit)="saveTruck()" (contextmenu)="onRightClick($event)">
    <div class="container-fluid border p-3">
        <!--START FORM -->
        <div class="container-fluid mt-3 px-xl-5">
            <div class="row d-flex justify-content-between">
                <div class="container-fluid mt-3 px-xl-5">
                    <h5 class="mb-3">Truck Information</h5>
                    <div class="row d-flex justify-content-between">
                        <div class="mb-2">
                            <app-toast #toastComponent></app-toast>
                        </div>
                        <div class="left-content col-12 col-lg-6 col-xl-6 mb-3">
                            <!-- TOAST -->
                            <div class="text-start text-lg-end">
                                <div class="row align-items-center d-flex flex-column flex-md-row mb-3">
                                    <div class="col-12 col-lg-4">
                                        <label class="col-form-label">Plate No:</label>
                                    </div>
                                    <div class="col-12 col-lg-8">
                                        <input type=" text" class="form-control" placeholder="" id="plateNo"
                                            name="plateNo" formControlName="plateNo">
                                    </div>
                                </div>

                                <div [ngClass]="inputStyle">
                                    <div class="col-12 col-lg-4">
                                        <label class="col-form-label">Truck Type:</label>
                                    </div>
                                    <div class="col-12 col-lg-8 d-flex">
                                        <input type="text" placeholder="Select Truck Type"
                                            [ngClass]="truckForm.get('truckTypeName')?.value ? 'form-control' : 'is-invalid form-control'"
                                            readonly (click)="openTruckModal()" id="truckTypeName" name="truckTypeName"
                                            formControlName="truckTypeName">
                                        <input type="hidden" id="truckTypeId" name="truckTypeId"
                                            formControlName="truckTypeId">
                                        <button type="button" class="input-group-text rounded-0" id="basic-addon1"
                                            [disabled]="isEdit" (click)="openTruckModal()"><i
                                                class="bi bi-search"></i></button>
                                    </div>
                                </div>

                                <!-- START TRUCKER ID MODAL -->
                                <div class="modal fade" id="truckTypeModal" tabindex="-1"
                                    aria-labelledby="truckTypeModalLabel" aria-hidden="true">
                                    <div class="modal-dialog modal-fullscreen-sm-down modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h1 class="modal-title fs-5" id="truckTypeModalLabel">Truck Type</h1>
                                                <button type="button" class="btn-close" (click)="closeTruckModal()"
                                                    aria-label="Close"></button>
                                            </div>
                                            <div class="modal-body">
                                                <ag-grid-angular style="width: 100%; height: 500px;"
                                                    class="ag-theme-alpine text-start" [rowData]="rowTruckData"
                                                    (cellClicked)="rowTruckClicked($event)"
                                                    [columnDefs]="columnTruckDefs" [defaultColDef]="defaultTruckColDef">
                                                </ag-grid-angular>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div [ngClass]="inputStyle">
                                    <div class="col-12 col-lg-4">
                                        <label class="col-form-label">Company Name:</label>
                                    </div>
                                    <div class="col-12 col-lg-8 d-flex">
                                        <input type="text" placeholder="Selected Trucker"
                                            [ngClass]="truckForm.get('truckerName')?.value ? 'form-control' : 'is-invalid form-control'"
                                            readonly (click)="openModal()" id="truckerName" name="truckerName"
                                            formControlName="truckerName">
                                        <input type="hidden" id="truckerId" name="truckerId"
                                            formControlName="truckerId">
                                        <button type="button" class="input-group-text rounded-0" id="basic-addon1"
                                            [disabled]="isEdit" (click)="openModal()"><i
                                                class="bi bi-search"></i></button>
                                    </div>
                                </div>

                                <!-- START TRUCKER ID MODAL -->
                                <div class="modal fade" id="truckerModal" tabindex="-1"
                                    aria-labelledby="truckerModalLabel" aria-hidden="true">
                                    <div class="modal-dialog modal-fullscreen-sm-down modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h1 class="modal-title fs-5" id="truckerModalLabel">Company Name</h1>
                                                <button type="button" class="btn-close" (click)="closeModal()"
                                                    aria-label="Close"></button>
                                            </div>
                                            <div class="modal-body">
                                                <ag-grid-angular style="width: 100%; height: 500px;"
                                                    class="ag-theme-alpine text-start" [rowData]="rowData"
                                                    (cellClicked)="rowClicked($event)" [columnDefs]="columnDefs"
                                                    [defaultColDef]="defaultColDef">
                                                </ag-grid-angular>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div class="row align-items-center d-flex flex-column flex-md-row mb-3">
                                    <div class="col-12 col-lg-4">
                                        <label class="col-form-label">Van Type:</label>
                                    </div>
                                    <div class="col-12 col-lg-8">
                                        <input type="text" class="form-control" placeholder="" id="vanType"
                                            name="vanType" formControlName="vanType">
                                    </div>
                                </div>
                                <div class="row align-items-center d-flex flex-column flex-md-row mb-3">
                                    <div class="col-12 col-lg-4">
                                        <label class="col-form-label">Plate Type:</label>
                                    </div>
                                    <div class="col-12 col-lg-8">
                                        <div class="dropdown w-100">
                                            <button
                                                class="btn border btn-md dropdown-toggle d-flex justify-content-between align-items-center w-100"
                                                type="button" data-bs-toggle="dropdown" aria-expanded="false"
                                                [attr.disabled]="truckForm.get('plateType')?.disabled ? true : null">
                                                <span>Select</span>
                                                <span class="dropdown-toggle-split"></span>
                                            </button>
                                            <ul class="dropdown-menu w-100">
                                                <li><a class="dropdown-item" href="#">Action</a></li>
                                                <li><a class="dropdown-item" href="#">Another action</a></li>
                                                <li><a class="dropdown-item" href="#">Something else here</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="row align-items-center d-flex flex-column flex-md-row mb-3">
                                    <div class="col-12 col-lg-4">
                                        <label class="col-form-label">Year Model:</label>
                                    </div>
                                    <div class="col-12 col-lg-8">
                                        <input type="text" class="form-control" placeholder="" id="year" name="year"
                                            formControlName="year">
                                    </div>
                                </div>
                                <div class=" row align-items-center d-flex flex-column flex-md-row mb-3">
                                    <div class="col-12 col-lg-4">
                                        <label class="col-form-label">Make:</label>
                                    </div>
                                    <div class="col-12 col-lg-8">
                                        <input type="text" class="form-control" placeholder="" id="make" name="make"
                                            formControlName="make">
                                    </div>
                                </div>
                                <div class="row align-items-center d-flex flex-column flex-md-row mb-3">
                                    <div class="col-12 col-lg-4">
                                        <label class="col-form-label">Body:</label>
                                    </div>
                                    <div class="col-12 col-lg-8">
                                        <input type="text" class="form-control" placeholder="" id="body" name="body"
                                            formControlName="body">
                                    </div>
                                </div>
                                <div class="row align-items-center d-flex flex-column flex-md-row mb-3">
                                    <div class="col-12 col-lg-4">
                                        <label class="col-form-label">Weight:</label>
                                    </div>
                                    <div class="col-12 col-lg-8">
                                        <input type="text" class="form-control" placeholder="" id="WeightCapacity"
                                            name="WeightCapacity" formControlName="weightCapacity">
                                    </div>
                                </div>
                                <div class="row align-items-center d-flex flex-column flex-md-row mb-3">
                                    <div class="col-12 col-lg-4">
                                        <label class="col-form-label">Chassis No:</label>
                                    </div>
                                    <div class="col-12 col-lg-8">
                                        <input type="text" class="form-control" placeholder="" id="chassisNumber"
                                            name="chassisNumber" formControlName="chassisNumber">
                                    </div>
                                </div>
                                <div class="row align-items-center d-flex flex-column flex-md-row mb-3">
                                    <div class="col-12 col-lg-4">
                                        <label class="col-form-label">Engine No:</label>
                                    </div>
                                    <div class="col-12 col-lg-8">
                                        <input type="text" class="form-control" id="engineNumber" name="engineNumber"
                                            formControlName="engineNumber">
                                    </div>
                                </div>

                                <div class="row align-items-center d-flex flex-column flex-md-row mb-3">
                                    <div class="col-12 col-lg-4">
                                        <label class="col-form-label">Ownership:</label>
                                    </div>
                                    <div class="col-12 col-lg-8">
                                        <div class="dropdown w-100">
                                            <button
                                                class="btn border btn-md dropdown-toggle d-flex justify-content-between align-items-center w-100"
                                                type="button" data-bs-toggle="dropdown" aria-expanded="false"
                                                [attr.disabled]="truckForm.get('ownershipTypeId')?.disabled ? true : null">
                                                <span>{{getOwnerShipTypeIdLabel()}}</span>
                                                <span class="dropdown-toggle-split"></span>
                                            </button>
                                            <ul class="dropdown-menu w-100">
                                                <li *ngFor="let option of ownerShipOptions">
                                                    <a class="dropdown-item"
                                                        (click)="selectOwnerShip(option.id); $event.preventDefault()">>
                                                        {{ option.label }}
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div class="row align-items-center d-flex flex-column flex-md-row mb-3">
                                    <div class="col-12 col-lg-4">
                                        <label class="col-form-label">Owner:</label>
                                    </div>
                                    <div class="col-12 col-lg-8">
                                        <input type="text" class="form-control" placeholder="" id="owner" name="owner"
                                            formControlName="owner">
                                    </div>
                                </div>
                                <div class="row align-items-center d-flex flex-column flex-md-row mb-3">
                                    <div class="col-12 col-lg-4">
                                        <label class="col-form-label">CR no:</label>
                                    </div>
                                    <div class="col-12 col-lg-8">
                                        <input type="text" class="form-control" placeholder="" id="crNumber"
                                            name="crNumber" formControlName="crNumber">
                                    </div>
                                </div>
                                <div class="row align-items-center d-flex flex-column flex-md-row mb-3">
                                    <div class="col-12 col-lg-4">
                                        <label class="col-form-label">CR Date:</label>
                                    </div>
                                    <div class="col-12 col-lg-8">
                                        <input type="date" class="form-control" placeholder="" id="crDate" name="crDate"
                                            formControlName="crDate">
                                    </div>
                                </div>
                                <div class="row align-items-center d-flex flex-column flex-md-row mb-3">
                                    <div class="col-12 col-lg-4">
                                        <label class="col-form-label">OR No:</label>
                                    </div>
                                    <div class="col-12 col-lg-8">
                                        <input type="text" class="form-control" placeholder="" id="orNumber"
                                            name="orNumber" formControlName="orNumber">
                                    </div>
                                </div>
                                <div class="row align-items-center d-flex flex-column flex-md-row mb-3">
                                    <div class="col-12 col-lg-4">
                                        <label class="col-form-label">OR Date:</label>
                                    </div>
                                    <div class="col-12 col-lg-8">
                                        <input type="date" class="form-control" placeholder="" id="orDate" name="orDate"
                                            formControlName="orDate">
                                    </div>
                                </div>
                                <div class="row align-items-center d-flex flex-column flex-md-row mb-3">
                                    <div class="col-12 col-lg-4">
                                        <label class="col-form-label">Acquisition Date:</label>
                                    </div>
                                    <div class="col-12 col-lg-8">
                                        <input type="date" class="form-control" placeholder="" id="acquisitionDate"
                                            name="acquisitionDate" formControlName="acquisitionDate">
                                    </div>
                                </div>
                                <div class="row align-items-center d-flex flex-column flex-md-row mb-3">
                                    <div class="col-12 col-lg-4">
                                        <label class="col-form-label">Acquisition Cost:</label>
                                    </div>
                                    <div class="col-12 col-lg-8">
                                        <input type="text" class="form-control" placeholder="" id="acquisitionCost"
                                            name="acquisitionCost" formControlName="acquisitionCost">
                                    </div>
                                </div>

                                <div class="row align-items-center d-flex flex-column flex-md-row mb-3">
                                    <div class="col-12 col-lg-4">
                                        <label class="col-form-label">Emission Result:</label>
                                    </div>
                                    <div class="col-12 col-lg-8">
                                        <div class="dropdown w-100">
                                            <button
                                                class="btn border btn-md dropdown-toggle d-flex justify-content-between align-items-center w-100"
                                                type="button" data-bs-toggle="dropdown" aria-expanded="false"
                                                [attr.disabled]="truckForm.get('emissionResult')?.disabled ? true : null">
                                                <span>Select</span>
                                                <span class="dropdown-toggle-split"></span>
                                            </button>
                                            <ul class="dropdown-menu w-100">
                                                <li><a class="dropdown-item" href="#">Action</a></li>
                                                <li><a class="dropdown-item" href="#">Another action</a></li>
                                                <li><a class="dropdown-item" href="#">Something else here</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="row align-items-center d-flex flex-column flex-md-row mb-3">
                                    <div class="col-12 col-lg-4">
                                        <label class="col-form-label">Emission Date:</label>
                                    </div>
                                    <div class="col-12 col-lg-8">
                                        <input type="date" class="form-control" placeholder="" id="emissionDate"
                                            name="emissionDate" formControlName="emissionDate">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="right-content col-12 col-lg-6 col-xl-6 mb-3">
                            <div class="text-end ">
                                <div class="d-flex align-items-center row">
                                    <div class=" col-12 col-lg-4 col-xl-3">
                                        <label class="col-form-label">hasGPS?:</label>
                                    </div>
                                    <div class="col-1 col-lg-6 col-xl-7 px-0 px-md-3 text-start">
                                        <input type="checkbox" id="" name="" value="" class="rounded-circle" id="hasGPS"
                                            name="hasGPS" formControlName="hasGPS">
                                    </div>
                                </div>
                                <div class="row align-items-center d-flex flex-column flex-md-row mb-3">
                                    <div class="col-12 col-lg-3">
                                        <label class="col-form-label">GPS Provider:</label>
                                    </div>
                                    <div class="col-12 col-lg-8">
                                        <input type="text" class="form-control" placeholder="" id="gpsProvider"
                                            name="gpsProvider" formControlName="gpsProvider">
                                    </div>
                                </div>
                                <div class="row align-items-center d-flex flex-column flex-md-row mb-3">
                                    <div class="col-12 col-lg-3">
                                        <label class="col-form-label">ODOMeter:</label>
                                    </div>
                                    <div class="col-12 col-lg-8">
                                        <input type="text" class="form-control" placeholder="" id="odoMeter"
                                            name="odoMeter" formControlName="odoMeter">
                                    </div>
                                </div>
                                <div class="row align-items-center d-flex flex-column flex-md-row mb-3">
                                    <div class="col-12 col-lg-3">
                                        <label class="col-form-label">Fuel Type:</label>
                                    </div>
                                    <div class="col-12 col-lg-8">
                                        <input type="text" class="form-control" placeholder="" id="fuelType"
                                            name="fuelType" formControlName="fuelType">
                                    </div>
                                </div>
                                <div class="row align-items-center d-flex flex-column flex-md-row mb-3">
                                    <div class="col-12 col-lg-3">
                                        <label class="col-form-label">Avekmiliter:</label>
                                    </div>
                                    <div class="col-12 col-lg-8">
                                        <input type="text" class="form-control" placeholder="" id="aveKMPerLiter"
                                            name="aveKMPerLiter" formControlName="aveKMPerLiter">
                                    </div>
                                </div>
                                <div class="row align-items-center d-flex flex-column flex-md-row mb-3">
                                    <div class="col-12 col-lg-3">
                                        <label class="col-form-label">Insurer:</label>
                                    </div>
                                    <div class="col-12 col-lg-8">
                                        <input type="text" class="form-control" placeholder="" id="insurer"
                                            name="insurer" formControlName="insurer">
                                    </div>
                                </div>
                                <div class="row align-items-center d-flex flex-column flex-md-row mb-3">
                                    <div class="col-12 col-lg-3">
                                        <label class="col-form-label">Coverage:</label>
                                    </div>
                                    <div class="col-12 col-lg-8">
                                        <input type="text" class="form-control" placeholder="" id="coverage"
                                            name="coverage" formControlName="coverage">
                                    </div>
                                </div>
                                <div class="row align-items-center d-flex flex-column flex-md-row mb-3">
                                    <div class="col-12 col-lg-3">
                                        <label class="col-form-label">Expiry Date:</label>
                                    </div>
                                    <div class="col-12 col-lg-8">
                                        <input type="date" class="form-control" placeholder="" id="insuranceExpiryDate"
                                            name="insuranceExpiryDate" formControlName="insuranceExpiryDate">
                                    </div>
                                </div>
                                <div class="row align-items-center d-flex flex-column flex-md-row mb-3">
                                    <div class="col-12 col-lg-3">
                                        <label class="col-form-label">Policy No:</label>
                                    </div>
                                    <div class="col-12 col-lg-8">
                                        <input type="text" class="form-control" placeholder="" id="policyNumber"
                                            name="policyNumber" formControlName="policyNumber">
                                    </div>
                                </div>
                                <div class="row align-items-center d-flex flex-column flex-md-row mb-3">
                                    <div class="col-12 col-lg-3">
                                        <label class="col-form-label">Deployment:</label>
                                    </div>
                                    <div class="col-12 col-lg-8">
                                        <input type="text" class="form-control" placeholder="" id="deployment"
                                            name="deployment" formControlName="deployment">
                                    </div>
                                </div>
                                <div class="row align-items-center d-flex flex-column flex-md-row mb-3">
                                    <div class="col-12 col-lg-3">
                                        <label class="col-form-label">Garage:</label>
                                    </div>
                                    <div class="col-12 col-lg-8">
                                        <input type="text" class="form-control" placeholder="" id="garage" name="garage"
                                            formControlName="garage">
                                    </div>
                                </div>
                                <div class="row align-items-center d-flex flex-column flex-md-row mb-3">
                                    <div class="col-12 col-lg-3">
                                        <label class="col-form-label">Is For Billing:</label>
                                    </div>
                                    <div class="col-1 px-0 px-md-4 text-start">
                                        <input type="checkbox" id="" name="" value="" class="rounded-circle"
                                            id="isForBilling" name="isForBilling" formControlName="isForBilling">
                                    </div>
                                    <!-- <div class="col-12 col-lg-7">
                                        <input type="date" class="form-control" placeholder="" id="isForBilling"
                                            name="isForBilling" formControlName="isForBilling">
                                    </div> -->
                                </div>
                                <div class="row align-items-center d-flex flex-column flex-md-row mb-3">
                                    <div class="col-12 col-lg-3">
                                        <label class="col-form-label">LTFRB Expirtation Date:</label>
                                    </div>
                                    <div class="col-12 col-lg-8">
                                        <input type="date" class="form-control" placeholder="" id="ltfrbExpirationDate"
                                            name="ltfrbExpirationDate" formControlName="ltfrbExpirationDate">
                                    </div>
                                </div>
                                <div [ngClass]="inputStyle" *ngIf="isEditMode">
                                    <div class="col-12 col-lg-3">
                                        <label class="col-form-label">Truck Status</label>
                                    </div>
                                    <div class="col-12 col-lg-8">
                                        <div class="dropdown w-100">
                                            <button [attr.disabled]="truckForm.get('status')?.disabled ? true : null"
                                                class="btn border btn-md dropdown-toggle d-flex justify-content-between align-items-center w-100"
                                                type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                <span>
                                                    {{ getTruckStatusLabel() }}
                                                </span>
                                                <span class="dropdown-toggle-split"></span>
                                            </button>
                                            <ul class="dropdown-menu w-100">
                                                <li *ngFor="let option of truckStatusOptions">
                                                    <a class="dropdown-item" href="#"
                                                        (click)="selectTruckStatus(option.value); $event.preventDefault()">
                                                        {{ option.label }}
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="row align-items-center d-flex flex-column flex-md-row mb-3">
                                    <div class="col-12 col-lg-3">
                                        <label class="col-form-label">Remarks:</label>
                                    </div>
                                    <div class="col-12 col-lg-8">
                                        <textarea id="remarks" name="remarks" rows="10" cols="50" class="form-control"
                                            placeholder="" id="remarks" name="remarks" formControlName="remarks">
                                            </textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="border border-bottom-0 p-2 bg-secondary-color py-3 header">
                <!-- <div class="d-flex gap-2 justify-content-end header"> -->
                <button type="button" class="border-0 header" (click)="showTagging()">Tagging</button>
                <button type="button" class="border-0 header" (click)="showMovementHistory()">Movement
                    History</button>
                <!-- </div> -->
            </div>
            <div *ngIf="showTaggingTable">
                <div *ngIf="!idFromTruckList">
                    <ag-grid-angular style="width: 100%; height: 500px;" class="ag-theme-alpine"
                        [columnDefs]="BusinessUnitcolumnDefs" [defaultColDef]="defaultColDef"
                        [rowData]="dataFromBusinessUnit" (cellValueChanged)="onCellValueChanged($event)"
                        (gridReady)="onGridReady($event)">
                    </ag-grid-angular>
                </div>
                <div *ngIf="idFromTruckList">
                    <ag-grid-angular style="width: 100%; height: 500px;" class="ag-theme-alpine"
                        [columnDefs]="CobrowDatacolumnDefs" [defaultColDef]="defaultColDef"
                        [rowData]="dataFromTrucktaggingAndTruck" (cellValueChanged)="onCellValueChanged($event)"
                        (gridReady)="onGridReady($event)">
                    </ag-grid-angular>
                </div>
                <!-- <ag-grid-angular style="width: 100%; height: 500px;" class="ag-theme-alpine"
                    [columnDefs]="CobrowDatacolumnDefs" [defaultColDef]="defaultColDef" [rowData]="dataFromTrucktaggingAndTruck
                    " (cellValueChanged)="onCellValueChanged($event)" (gridReady)="onGridReady($event)">
                </ag-grid-angular> -->
            </div>
            <div *ngIf="showMovementHistoryTable">
                <ag-grid-angular style="width: 100%; height: 500px;" class="ag-theme-alpine"
                    [columnDefs]="truckMovementHistorycolumnDefs" [defaultColDef]="defaultColDef"
                    [rowData]="dataFromTruckMovementHistory" (gridReady)="onGridReady($event)">
                </ag-grid-angular>
            </div>
            <div class="border-top w-100 d-flex justify-content-end gap-2">
                <button type="button" class="btn mt-2">Cancel</button>
                <button type="submit" class="btn btn-sm btn-primary mt-2">Submit</button>
            </div>
        </div>
        <!-- END FORM -->
    </div>

</form>
<div *ngIf="displayDropdown" class="dropdown-menu"
    [ngStyle]="{'top': contextMenuPosition.y, 'left': contextMenuPosition.x,'display': displayDropdown ? 'block' : 'none' ,'position': 'absolute'}">
    <button class="dropdown-item" (click)="onFindDriver()">Find</button>
    <button class="dropdown-item" [disabled]="!userHasEditAccess" (click)="onEditDriver()">Edit</button>
    <button class="dropdown-item" [disabled]="!userHasCreateAccess" (click)="onCreateTruck()">Create</button>
</div>