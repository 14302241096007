<div class="container-fluid px-0">
    <div class="border border-bottom-0 p-2 bg-secondary-color py-3 header">
        <div class="d-flex justify-content-between align-items-center">
            <span class="fw-bold">Driver</span>
            <ul class="nav nav-underline">
                <li class="nav-item">
                    <a routerLink="/driver/form"
                        class="link-underline-opacity-0 link-underline text-secondary">Information</a>
                </li>
                <li class="nav-item">
                    <a routerLink="/driver/list" class="link-underline-opacity-0 link-underline text-secondary">List</a>
                </li>
            </ul>
        </div>
    </div>
</div>
<form [formGroup]="driverForm" (submit)="saveDriver()" (contextmenu)="onRightClick($event)">
    <div class="container-fluid border p-3">
        <!--START FORM -->
        <div class="container-fluid mt-3 px-xl-5">
            <div class="row d-flex justify-content-between">
                <div class="mb-2">
                    <app-toast #toastComponent></app-toast>
                </div>
                <!--START PERSONAL INFO -->
                <div class="left-content col-12 col-lg-6 col-xl-6 mb-3">
                    <!-- TOAST -->
                    <h5 class="mb-3">Personal Information</h5>
                    <div class="text-start text-lg-end">
                        <div [ngClass]="inputStyle">
                            <div class="col-12 col-lg-4">
                                <label class="col-form-label">First Name:</label>
                            </div>
                            <div class="col-12 col-lg-8">
                                <input type="text"
                                    [ngClass]="driverForm.get('firstName')?.value ? 'form-control' : 'is-invalid form-control'"
                                    placeholder="" id="firstName" name="firstName" formControlName="firstName">
                            </div>
                        </div>
                        <div [ngClass]="inputStyle">
                            <div class="col-12 col-lg-4">
                                <label class="col-form-label">Last Name:</label>
                            </div>
                            <div class="col-12 col-lg-8">
                                <input type="text"
                                    [ngClass]="driverForm.get('lastName')?.value ? 'form-control' : 'is-invalid form-control'"
                                    placeholder="" id="lastName" name="lastName" formControlName="lastName">
                            </div>
                        </div>
                        <div [ngClass]=" inputStyle">
                            <div class="col-12 col-lg-4">
                                <label class="col-form-label">Middle Name:</label>
                            </div>
                            <div class="col-12 col-lg-8">
                                <input type="text" class="form-control" placeholder="" id="lastName" name="lastName"
                                    formControlName="middleName">
                            </div>
                        </div>
                        <div [ngClass]="inputStyle">
                            <div class="col-12 col-lg-4">
                                <label class="col-form-label">Nick Name:</label>
                            </div>
                            <div class="col-12 col-lg-8">
                                <input type="text" class="form-control" placeholder="" id="lastName" name="lastName"
                                    formControlName="nickName">
                            </div>
                        </div>
                        <div [ngClass]="inputStyle">
                            <div class="col-12 col-lg-4">
                                <label class="col-form-label">Contact No.:</label>
                            </div>
                            <div class="col-12 col-lg-8">
                                <input type="text" class="form-control" placeholder="" id="contactNumber"
                                    name="contactNumber" formControlName="contactNumber">
                            </div>
                        </div>
                        <div [ngClass]="inputStyle">
                            <div class="col-12 col-lg-4">
                                <label class="col-form-label">Years of Service:</label>
                            </div>
                            <div class="col-12 col-lg-8">
                                <input type="text" class="form-control" placeholder="" id="yearsOfService"
                                    name="yearsOfService"
                                    [attr.disabled]="driverForm.get('yearsOfService')?.disabled ? true : null">
                            </div>
                        </div>
                        <div [ngClass]="inputStyle">
                            <div class="col-12 col-lg-4">
                                <label class="col-form-label">License No:</label>
                            </div>
                            <div class="col-12 col-lg-8">
                                <input type="text" class="form-control" placeholder="" id="licenseNo" name="licenseNo"
                                    formControlName="licenseNo">
                            </div>
                        </div>
                        <div [ngClass]="inputStyle">
                            <div class="col-12 col-lg-4">
                                <label class="col-form-label">licenseType</label>
                            </div>
                            <div class="col-12 col-lg-8">
                                <input type="text" class="form-control" placeholder="" id="licenseType"
                                    name="licenseType" formControlName="licenseType">
                            </div>
                        </div>
                        <div [ngClass]="inputStyle">
                            <div class="col-12 col-lg-4">
                                <label class="col-form-label">License Expiration Date:</label>
                            </div>
                            <div class="col-12 col-lg-8">
                                <input type="date" class="form-control" id="licenseExpiry" name="licenseExpiry"
                                    formControlName="licenseExpiry">
                            </div>
                        </div>
                    </div>
                </div>
                <!--END PERSONAL INFO -->

                <!--START 3PL INFORMATION -->
                <div class="right-content col-12 col-lg-6 col-xl-6 mb-3">
                    <h5 class="mb-3">3PL Information</h5>
                    <div class="text-end">
                        <div [ngClass]="inputStyle">
                            <div class="col-12 col-lg-3">
                                <label class="col-form-label">Company Name:</label>
                            </div>
                            <div class="col-12 col-lg-8 d-flex">
                                <input type="text" placeholder="Selected Trucker"
                                    [ngClass]="driverForm.get('truckerId')?.value ? 'form-control' : 'is-invalid form-control'"
                                    readonly (click)="openModal()" id="truckerName" name="truckerName"
                                    formControlName="truckerName">
                                <input type="hidden" id="truckerId" name="truckerId" formControlName="truckerId">
                                <button type="button" class="input-group-text rounded-0" id="basic-addon1"
                                    [disabled]="isEdit" (click)="openModal()"><i class="bi bi-search"></i></button>
                            </div>
                        </div>

                        <!-- START TRUCKER ID MODAL -->
                        <div class="modal fade" id="truckerModal" tabindex="-1" aria-labelledby="truckerModalLabel"
                            aria-hidden="true">
                            <div class="modal-dialog modal-fullscreen-sm-down modal-lg">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h1 class="modal-title fs-5" id="truckerModalLabel">Company Name</h1>
                                        <button type="button" class="btn-close" (click)="closeModal()"
                                            aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                        <ag-grid-angular style="width: 100%; height: 500px;"
                                            class="ag-theme-alpine text-start" [rowData]="rowData"
                                            (cellClicked)="rowClicked($event)" [columnDefs]="columnDefs"
                                            [defaultColDef]="defaultColDef">
                                        </ag-grid-angular>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <!-- END TRUCKER ID MODAL -->

                        <div [ngClass]="inputStyle">
                            <div class="col-12 col-lg-3">
                                <label class="col-form-label">Date Hired:</label>
                            </div>
                            <div class="col-12 col-lg-8">
                                <input type="date" class="form-control" id="date" name="Date"
                                    formControlName="dateHire">
                            </div>
                        </div>
                        <div [ngClass]="inputStyle">
                            <div class="col-12 col-lg-3">
                                <label class="col-form-label">Years of Service 3PL:</label>
                            </div>
                            <div class="col-12 col-lg-8">
                                <input type="text" class="form-control" placeholder="" id="yearsOfService"
                                    name="yearsOfService" formControlName="yearsOfService">
                            </div>
                        </div>
                        <div [ngClass]="inputStyle">
                            <div class="col-12 col-lg-3">
                                <label class="col-form-label">Driver is banned?:</label>
                            </div>
                            <div class="col-12 col-lg-8">
                                <div class="dropdown w-100">
                                    <button
                                        class="btn border btn-md dropdown-toggle d-flex justify-content-between align-items-center w-100"
                                        type="button" data-bs-toggle="dropdown" aria-expanded="false"
                                        [attr.disabled]="driverForm.get('driverBlacklistTypeId')?.disabled ? true : null">
                                        <span>{{ selectedBlacklistType }}</span>
                                        <span class="dropdown-toggle-split"></span>
                                    </button>
                                    <ul class="dropdown-menu custom-dropdown-height w-100">
                                        <li *ngFor="let item of blacklist">
                                            <a class="dropdown-item" (click)="onBlackListTypeSelectItem(item)">
                                                {{ item.description }}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="inputStyle" *ngIf="isEditMode">
                            <div class="col-12 col-lg-3">
                                <label class="col-form-label">Employment Status</label>
                            </div>
                            <div class="col-12 col-lg-8">
                                <div class="dropdown w-100">
                                    <button [attr.disabled]="driverForm.get('status')?.disabled ? true : null"
                                        class="btn border btn-md dropdown-toggle d-flex justify-content-between align-items-center w-100"
                                        type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <span>
                                            {{ getEmploymentStatusLabel() }}
                                        </span>
                                        <span class="dropdown-toggle-split"></span>
                                    </button>
                                    <ul class="dropdown-menu w-100">
                                        <li *ngFor="let option of employmentStatusOptions">
                                            <a class="dropdown-item" href="#"
                                                (click)="selectEmploymentStatus(option.value); $event.preventDefault()">
                                                {{ option.label }}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--END 3PL INFORMATION -->
            </div>
        </div>
        <div class="container-fluid mt-3 px-xl-5">
            <!--START DOCUMENT INFORMATION -->
            <div class="row d-flex">
                <h5 class="mb-3">Document Information</h5>
                <div class="left-content col-12 col-lg-6 col-xl-6">
                    <div class="text-end">
                        <div [ngClass]="docInputStyle">
                            <div class="col-12 col-lg-4 col-xl-3">
                                <label class="col-form-label">Biodata:</label>
                            </div>
                            <div class="col-1 col-lg-6 col-xl-7 px-0 px-md-4 text-start">
                                <input type="checkbox" id="" name="" value="" class="rounded-circle" id="Biodata"
                                    name="Biodata" formControlName="hasBiodata">
                            </div>
                        </div>
                        <div [ngClass]="docInputStyle">
                            <div class="col-12 col-lg-4 col-xl-3">
                                <label class="col-form-label">NBI Clearance:</label>
                            </div>
                            <div class="col-1 col-lg-6 col-xl-7 px-0 px-md-4 text-start">
                                <input type="checkbox" id="" name="" value="" class="rounded-circle" id="NBI" name="NBI"
                                    formControlName="hasNBIClearance">
                            </div>
                        </div>
                        <div [ngClass]="docInputStyle">
                            <div class="col-12 col-lg-4 col-xl-3">
                                <label class="col-form-label">Barangay Clearance:</label>
                            </div>
                            <div class="col-1 col-lg-6 col-xl-7 px-0 px-md-4 text-start">
                                <input type="checkbox" id="" name="" value="" class="rounded-circle"
                                    id="hasBarangayClearance" name="hasBarangayClearance"
                                    formControlName="hasBarangayClearance">
                            </div>
                        </div>
                        <div [ngClass]="docInputStyle">
                            <div class="col-12 col-lg-4 col-xl-3">
                                <label class="col-form-label">Police Clearance:</label>
                            </div>
                            <div class="col-1 col-lg-6 col-xl-7 px-0 px-md-4 text-start">
                                <input type="checkbox" id="" name="" value="" class="rounded-circle"
                                    id="hasPoliceClearance" name="hasPoliceClearance"
                                    formControlName="hasPoliceClearance">
                            </div>
                        </div>


                        <div [ngClass]="inputStyle">
                            <div class="col-12 col-lg-4 col-xl-3">
                                <label class="col-form-label">APEDate:</label>
                            </div>
                            <div class="col col-lg-8">
                                <input type="date" class="form-control" id="apeDate" name="apeDate"
                                    formControlName="apeDate">
                            </div>
                        </div>
                        <div [ngClass]="inputStyle">
                            <div class="col-12 col-lg-4 col-xl-3">
                                <label class="col-form-label">Drug Test Date:</label>
                            </div>
                            <div class="col col-lg-8 text-start ">
                                <input type="date" class="form-control" id="date" id="drugTestDate" name="drugTestDate"
                                    formControlName="drugTestDate">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="right-content col-12 col-lg-6 col-xl-6 mb-3">
                    <div class="text-end">
                        <div [ngClass]="docInputStyle">
                            <div class="col-12 col-lg-4 col-xl-3">
                                <label class="col-form-label">Pre-Defensive Drive Course:</label>
                            </div>
                            <div class="col-1 col-lg-6 col-xl-6 text-start px-0 px-md-4">
                                <input type="checkbox" id="" name="" value="" class="rounded-circle"
                                    id="hasPreDefensiveDriveCourse" name="hasPreDefensiveDriveCourse"
                                    formControlName="hasPreDefensiveDriveCourse">
                            </div>
                        </div>
                        <div [ngClass]="docInputStyle">
                            <div class="col-12 col-lg-4 col-xl-3">
                                <label class="col-form-label">Defensive Drive Course:</label>
                            </div>
                            <div class="col-1 col-lg-6 col-xl-6 text-start px-0 px-md-4">
                                <input type="checkbox" id="" name="" value="" class="rounded-circle"
                                    id="hasDefensiveDriveCourse" name="hasDefensiveDriveCourse"
                                    formControlName="hasDefensiveDriveCourse">
                            </div>
                        </div>
                        <div [ngClass]="docInputStyle">
                            <div class="col-12 col-lg-4 col-xl-3">
                                <label class="col-form-label">Safety, Health, Environment:</label>
                            </div>
                            <div class="col-1 col-lg-6 col-xl-6 text-start px-0 px-md-4">
                                <input type="checkbox" id="" name="" value="" class="rounded-circle" id="hasSHE"
                                    name="hasSHE" formControlName="hasSHE">
                            </div>
                        </div>
                        <div [ngClass]="docInputStyle">
                            <div class="col-12 col-lg-4 col-xl-3">
                                <label class="col-form-label">Good Warehouse Practice:</label>
                            </div>
                            <div class="col-1 col-lg-6 col-xl-6 text-start px-0 px-md-4">
                                <input type="checkbox" id="" name="" value="" class="rounded-circle"
                                    id="hasGoodWarehousePractice" name="hasGoodWarehousePractice"
                                    formControlName="hasGoodWarehousePractice">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--END DOCUMENT INFORMATION -->

            <div class="border-top w-100 d-flex justify-content-end gap-2">
                <button type="button" class="btn mt-2" *ngIf="isEditMode" (click)="onCancel()">Cancel</button>
                <button type="submit" class="btn btn-sm btn-primary mt-2" *ngIf="isEditMode">Submit</button>
            </div>
        </div>
        <!-- END FORM -->
    </div>
</form>
<div *ngIf="displayDropdown" class="dropdown-menu"
    [ngStyle]="{'top': contextMenuPosition.y, 'left': contextMenuPosition.x, 'display': displayDropdown ? 'block' : 'none'}">
    <button class="dropdown-item" (click)="onFindDriver()">Find</button>
    <button class="dropdown-item" [disabled]="!userHasEditAccess" (click)="onEditDriver()">Edit</button>
    <button class="dropdown-item" [disabled]="!userHasCreateAccess" (click)="onCreateDriver()">Create</button>
</div>