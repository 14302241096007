import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DriverlistComponent } from './component/DriverMaintenance/driverlist/driverlist.component';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { DriverService } from './service/driver.service';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';  // required for Angular Material
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { ToastComponent } from './component/toast/toast.component';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { AgGridModule } from 'ag-grid-angular';
import { ReactiveFormsModule } from '@angular/forms';
import { TrucklistComponent } from './component/TruckMaintenance/trucklist/trucklist.component';
import { DriverBlacklistComponent } from './component/driver-blacklist/driver-blacklist.component';
import { TokenComponent } from './component/token/token.component';
import { LayoutComponent } from './shared/layout/layout.component';
import { UnauthorizedComponent } from './component/unauthorized/unauthorized.component';

@NgModule({
  declarations: [
    AppComponent,
    DriverlistComponent,
    ToastComponent,
    TrucklistComponent,
    DriverBlacklistComponent,
    TokenComponent,
    LayoutComponent,
    UnauthorizedComponent,
  ],
  imports: [
    AgGridModule,
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatDialogModule,
  ],
  providers: [provideHttpClient(withInterceptorsFromDi()), DriverService, provideAnimationsAsync()],
  bootstrap: [AppComponent]
})
export class AppModule { }
