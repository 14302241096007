import { Component, OnInit, HostListener, ViewChild, } from '@angular/core';
import { TruckService } from '../../../service/truck.service';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { TruckFilterCriteria } from '../../../model/truckFilterCriteria.model';
import { RowDoubleClickedEvent, GridApi } from 'ag-grid-community';
import { Router, ActivatedRoute } from '@angular/router';
import { ColDef, CellContextMenuEvent } from 'ag-grid-community';
import { Truck } from '../../../model/truck.model';
import { TokenService } from '../../../service/token.service';
import { TruckerService } from '../../../service/trucker.service';
import { BusinessunitService } from '../../../service/businessunit.service';
import { TrucktypeService } from '../../../service/trucktype.service';
import { TruckOperationSiteTaggingService } from '../../../service/truck-operation-site-tagging.service';
import { TruckmovementhistoryService } from '../../../service/truckmovementhistory.service';
import { ToastComponent } from '../../toast/toast.component';
import { forkJoin } from 'rxjs';

declare var bootstrap: any;

@Component({
  selector: 'app-trucklist',
  templateUrl: './trucklist.component.html',
  styleUrls: ['./trucklist.component.css'],
})
export class TrucklistComponent implements OnInit {
  inputStyle = 'row align-items-center d-flex flex-column flex-md-row mb-3';
  @ViewChild(ToastComponent) toastComponent!: ToastComponent;

  truckStatusOptions = [
    { value: true, label: 'Active' },
    { value: false, label: 'Inactive' }
  ];
  ownerShipOptions = [
    { id: 1, label: 'COMPANY' },
    { id: 2, label: 'LEASED' },
    { id: 3, label: 'MORTGAGE' },
    { id: 4, label: 'OUTSOURCED' },
    { id: 5, label: 'OWNED' },
    { id: 6, label: 'PRIVATE' },
  ];
  showTaggingTable: boolean = true;
  showMovementHistoryTable: boolean = false;
  contextMenuPosition = { x: '0px', y: '0px' };
  myToken: string = '';
  jwt: string = '';
  UserName: string = '';
  isEdit: boolean = false;
  clickedRowData: any = null;
  isEditMode: boolean = false;
  truckId: string | null = null;
  disableSubmit: boolean = false;
  truckTypeId: string | null = null;
  fieldDisabled: boolean = false;
  displayDropdown = false;
  truckTaggingEdit: boolean = false;
  access: any[] = [];
  businessUnitData: any[] = [];
  truckList: any[] = [];
  truckerData: any[] = [];
  truckTypeData: any[] = [];
  truckOpsData: any[] = [];
  rowData: any[] = [];
  rowTruckData: any[] = [];
  updateTruckTagging: any[] = [];
  selectedTruckId: string | null = null;
  dataFromtruckTagging: any[] = [];
  dataFromTrucktaggingAndTruck: any[] = []
  dataFromBusinessUnit: any[] = [];
  dataFromTruckMovementHistory: any[] = [];
  userHasEditAccess: boolean = false;
  userHasCreateAccess: boolean = false;
  userHasViewAccess: boolean = false;
  userHasDeleteAccess: boolean = false;
  menuId: number = 2
  isTruckOpsEdit: boolean = false;
  private gridApi: GridApi | undefined;

  // CobrowDatacolumnDefs: ColDef[] = [
  //     { field: 'businessUnitId', headerName: 'BusinessUnit ID' },
  //     { field: 'businessUnitName', headerName: 'BusinessUnit Name' },
  //     { field: 'isDedicated', headerName: 'isDedicated', editable: (params) => !this.fieldDisabled, cellRenderer: 'agCheckboxCellRenderer' },
  //     { field: 'status', headerName: 'Status', editable: (params) => !this.isEdit, cellRenderer: 'agCheckboxCellRenderer' },
  //     { field: 'remarks', headerName: 'Remarks', editable: (params) => !this.isEdit },
  //     { field: 'createdDate', headerName: 'Created Date', valueFormatter: this.dateFormatter },
  //     { field: 'lastUpdatedDate', headerName: 'LastUpdate Date', valueFormatter: this.dateFormatter },
  // ]
  CobrowDatacolumnDefs: ColDef[] = [
    { field: 'businessUnitId', headerName: 'BusinessUnit ID' },
    { field: 'businessUnitName', headerName: 'BusinessUnit Name' },
    { field: 'isDedicated', headerName: 'isDedicated', editable: (params) => this.isTruckOpsEdit, cellRenderer: 'agCheckboxCellRenderer' },
    { field: 'status', headerName: 'Status', editable: (params) => this.isTruckOpsEdit, cellRenderer: 'agCheckboxCellRenderer' },
    { field: 'remarks', headerName: 'Remarks', editable: (params) => this.isTruckOpsEdit },
    { field: 'createdDate', headerName: 'Created Date', valueFormatter: this.dateFormatter },
    { field: 'lastUpdatedDate', headerName: 'LastUpdate Date', valueFormatter: this.dateFormatter },
  ]

  BusinessUnitcolumnDefs: ColDef[] = [
    { field: 'businessUnitId', headerName: 'Business' },
    { field: 'businessUnitName', headerName: 'BusinessUnit Name' },
    { field: 'isDedicated', headerName: 'isDedicated', editable: true, cellRenderer: 'agCheckboxCellRenderer' },
    { field: 'status', headerName: 'Status', editable: true, cellRenderer: 'agCheckboxCellRenderer' },
    { field: 'remarks', headerName: 'Remarks', editable: true },
  ]
  truckMovementHistorycolumnDefs: ColDef[] = [
    { field: 'businessUnitName', headerName: 'BusinessUnit Name' },
    { field: 'description', headerName: 'description' },
    { field: 'createdByUserId', headerName: 'CreatedByUserId' },
    { field: 'createdDate', headerName: 'CreatedDate', valueFormatter: this.dateFormatter },
  ]

  getColumnDef(): ColDef[] {
    return this.selectedTruckId === undefined || null ? this.BusinessUnitcolumnDefs : this.CobrowDatacolumnDefs;
  }
  getData() {
    return this.selectedTruckId === undefined || null ? this.dataFromBusinessUnit : this.dataFromTrucktaggingAndTruck
  }

  columnBusinessUnitDefs: ColDef[] = [
    { field: 'id', headerName: 'Location' },
    { field: 'name', headerName: 'description' },
    { field: 'status', headerName: 'Status' },
  ];

  columnTruckDefs: ColDef[] = [
    { field: 'id', headerName: 'ID' },
    { field: 'type', headerName: 'Truck Type' },
  ];

  columnDefs: ColDef[] = [
    { field: 'id', headerName: 'ID' },
    { field: 'name', headerName: 'Company Name' },
  ];

  public defaultTruckColDef: ColDef = {
    flex: 1,
    minWidth: 150,
    filter: true,
    floatingFilter: true,
  };
  public defaultColDef: ColDef = {
    flex: 1,
    minWidth: 150,
    filter: true,
    floatingFilter: true,
  };

  searchForm: FormGroup;
  currentTabIndex: number = 0; // Default to tab2Content
  criteriaList: TruckFilterCriteria[] = [];
  filteredRowData: any[] = [];
  isLoading: boolean = false;
  selectedFilter: string | null = null;
  selectedTruck: Truck | null = null;
  isNotif: boolean = false;
  notif: any = {
    header: '',
    status: '', // Can be 'success', 'error', 'info', etc.
    message: ''
  };
  truckListcolumnDefs: ColDef[] = [
    { field: 'plateNo', headerName: 'Plate No' },
    { field: 'truckName', headerName: 'Trucker Name' },
    { field: 'createdDate', headerName: 'Created Date', valueFormatter: this.dateFormatter },
    { field: 'status', headerName: 'Status' },
  ];

  showToast(message: string, header: string, status: string) {
    this.toastComponent.message = message;
    this.toastComponent.header = header;
    this.toastComponent.status = status;
    this.toastComponent.showToast();
  }
  updateNotification(message: string, header: string, status: string,) {
    this.isNotif = true;
    this.notif.header = header;
    this.notif.status = status;
    this.notif.message = message;
  }
  constructor(private route: ActivatedRoute, private truckService: TruckService, private truckerService: TruckerService, private truckTypeService: TrucktypeService, private router: Router, private businessUnitService: BusinessunitService, private fb: FormBuilder, private truckOperationSiteTagging: TruckOperationSiteTaggingService, private truckMovement: TruckmovementhistoryService, private tokenService: TokenService) {
    this.searchForm = this.fb.group({
      selectedOption: ['plateNo'],
      plateNoFrom: [''],
      plateNoTo: [''],
      truckerNameFrom: [''],
      truckerNameTo: [''],
      startDate: [''],
      endDate: [''],
    });
  }
  truckForm: FormGroup = new FormGroup({
    plateNo: new FormControl({ value: '', disabled: false }),
    truckTypeId: new FormControl({ value: '', disabled: false }),
    truckTypeName: new FormControl({ value: '', disabled: false }),
    truckerId: new FormControl({ value: '', disabled: false }),
    truckerName: new FormControl({ value: '', disabled: false }),
    vanType: new FormControl({ value: '', disabled: false }),
    plateType: new FormControl({ value: 0, disabled: false }),
    year: new FormControl({ value: null, disabled: false }),
    make: new FormControl({ value: '', disabled: false }),
    body: new FormControl({ value: '', disabled: false }),
    weightCapacity: new FormControl({ value: null, disabled: false }),
    volumeCapacity: new FormControl({ value: null, disabled: false }),
    length: new FormControl({ value: null, disabled: false }),
    width: new FormControl({ value: null, disabled: false }),
    height: new FormControl({ value: null, disabled: false }),
    chassisNumber: new FormControl({ value: '', disabled: false }),
    engineNumber: new FormControl({ value: '', disabled: false }),
    companyName: new FormControl({ value: '', disabled: false }),
    isLockin: new FormControl({ value: null, disabled: false }),
    ownershipTypeId: new FormControl({ value: '', disabled: false }),
    owner: new FormControl({ value: '', disabled: false }),
    crnumber: new FormControl({ value: '', disabled: false }),
    crdate: new FormControl({ value: '', disabled: false }),
    ornumber: new FormControl({ value: '', disabled: false }),
    ordate: new FormControl({ value: '', disabled: false }),
    acquisitionDate: new FormControl({ value: '', disabled: false }),
    acquisitionCost: new FormControl({ value: 0, disabled: false }),
    emissionResult: new FormControl({ value: false, disabled: false }),
    emissionDate: new FormControl({ value: '', disabled: false }),
    hasGps: new FormControl({ value: null, disabled: false }),
    gpsprovider: new FormControl({ value: '', disabled: false }),
    odoMeter: new FormControl({ value: null, disabled: false }),
    fuelType: new FormControl({ value: '', disabled: false }),
    aveKmperLiter: new FormControl({ value: null, disabled: false }),
    insurer: new FormControl({ value: '', disabled: false }),
    coverage: new FormControl({ value: null, disabled: false }),
    insuranceExpiryDate: new FormControl({ value: '', disabled: false }),
    policyNumber: new FormControl({ value: '', disabled: false }),
    sledger: new FormControl({ value: '', disabled: false }),
    deployment: new FormControl({ value: '', disabled: false }),
    garage: new FormControl({ value: '', disabled: false }),
    isforBilling: new FormControl({ value: false, disabled: false }),
    ltfrbexpirationDate: new FormControl({ value: '', disabled: false }),
    remarks: new FormControl({ value: '', disabled: false }),
    createdDate: new FormControl({ value: '', disabled: false }),
    lastUpdatedDate: new FormControl({ value: '', disabled: false }),
    status: new FormControl({ value: true, disabled: false }),
    createdByUserId: new FormControl({ value: '', disabled: false }),
    lastUpdatedByUserId: new FormControl({ value: '', disabled: false }),

  });

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (params['config']) {
        try {
          const config = JSON.parse(decodeURIComponent(params['config']));
          const token = config?.Id; // Extract `authToken` from params
          const menuId = params['menuId']; // Extract menu ID

          if (!token) {
            this.router.navigate(['/unauthorized']);
            this.updateNotification('please contact the developers, thank you.', 'Failed', 'danger');
            return;
          }

          if (!menuId) {
            this.router.navigate(['/unauthorized']);
            this.updateNotification('please contact the developers, thank you.', 'Failed', 'danger');
            return;
          }

          this.myToken = token;
          this.menuId = menuId;

          // Fetch user access and JWT token from the backend
          this.tokenService.getAccess(token, menuId).subscribe({
            next: (data: any) => {
              const jwtToken = data?.token; // JWT from backend
              const authToken = token; // Auth Token from params
              if (jwtToken) {
                this.tokenService.setToken(jwtToken, authToken); // Store both tokens
                this.jwt = jwtToken;
              } else {
                if (!data) {
                  this.isNotif = true
                  this.router.navigate(['/unauthorized']);
                  this.updateNotification('to access please contact the developers', 'Failed', 'danger')
                }
              }
              this.truckOperationSiteTagging.getBusinessUnitAndTruckOps().subscribe({
                next: (data) => {
                  this.dataFromBusinessUnit = data;
                }
              });
              this.fetchTruckersAndTypes()

              if (data?.access && Array.isArray(data.access)) {
                this.access = data.access;
                this.userHasEditAccess = this.access.some((item) => item.access === 'EDIT');
                this.userHasCreateAccess = this.access.some((item) => item.access === 'CREATE');
                this.userHasViewAccess = this.access.some((item) => item.access === 'VIEW');
                this.userHasDeleteAccess = this.access.some((item) => item.access === 'DELETE');
              } else {
                this.router.navigate(['/unauthorized']);
                // console.error('Expected access array but received:', data);
                this.access = [];
              }

            },
            error: () => {
              this.isNotif = true
              this.router.navigate(['/unauthorized']);
              this.updateNotification('please contact the developers, thank you.', 'Failed', 'danger');
            },
          });
        } catch (error) {
          this.isNotif = true
          this.updateNotification('please contact the developers, thank you.', 'Failed', 'danger');
        }
      } else {
        this.router.navigate(['/unauthorized']);
        this.tokenService.clearTokens()
        this.isNotif = true
        return;
      }
    });

    const storedCriteriaList = JSON.parse(localStorage.getItem('criteriaList') || '[]');
    if (storedCriteriaList.length > 0) {
      this.criteriaList = storedCriteriaList; // Set the criteria list from local storage
      this.searchTrucks(); // Search with the retrieved criteria
    }
  }
  loadTruckerData() {
    return this.truckerService.getAllTruckers();
  }
  loadTruckTypeData() {
    return this.truckTypeService.getAllTruckType()
  }

  formatDate(date: string | Date): string {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const day = String(d.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  fetchTruck(id: string | number) {

    if (!id && this.jwt === '') {
      this.isNotif = true
      this.updateNotification('blacklist ID', 'Invalid', 'warning');
      return;
    }
    const jwt = this.tokenService.getJwt();
    if (!jwt) {
      this.isNotif = true
      this.updateNotification('please contact developers', 'Failed', 'warning');
      return;
    }
    forkJoin({
      truck: this.truckService.getTruckById(id.toString()),
      truckerData: this.loadTruckerData(),
      truckTypeData: this.loadTruckTypeData()
    }).subscribe(({ truck, truckerData, truckTypeData }) => {
      if (!truck) {
        this.isNotif = true
        this.updateNotification('Truck not found.', 'Failed', 'danger')
        return;
      }
      console.log(truck)
      this.truckOperationSiteTagging.getBusinessUnitAndTruckOpsLinq(truck.id).subscribe({
        next: (data) => {
          this.dataFromTrucktaggingAndTruck = data;
        }
      });

      const trucker = truckerData.find(t => t.id === truck.truckerId);
      const truckType = truckTypeData.find(t => t.id === truck.truckTypeId);

      this.truckForm.patchValue({
        truckerName: trucker ? trucker.name : null,
        truckTypeName: truckType ? truckType.type : null,
        ...truck,
        crdate: truck.crdate ? new Date(truck.crdate).toISOString().split('T')[0] : null,
        ordate: truck.ordate ? new Date(truck.ordate).toISOString().split('T')[0] : null,
        acquisitionDate: truck.acquisitionDate ? new Date(truck.acquisitionDate).toISOString().split('T')[0] : null,
        emissionDate: truck.emissionDate ? new Date(truck.emissionDate).toISOString().split('T')[0] : null,
        insuranceExpiryDate: truck.insuranceExpiryDate ? new Date(truck.insuranceExpiryDate).toISOString().split('T')[0] : null,
        ltfrbexpirationDate: truck.ltfrbexpirationDate ? new Date(truck.ltfrbexpirationDate).toISOString().split('T')[0] : null,
      });
    }, error => {
      this.isNotif = true
      this.updateNotification('please contact the developers, thank you.', 'Failed', 'danger')
    });
  }

  @HostListener('window:beforeunload', ['$event'])
  handleBeforeUnload(event: Event): void {
    sessionStorage.setItem('pageRefreshed', 'true');
  }

  // Method to fetch truckers and truck types
  fetchTruckersAndTypes(): Promise<void> {
    return new Promise((resolve, reject) => {
      // Fetch truckers
      this.truckerService.getAllTruckers().subscribe({
        next: (data) => {
          this.truckerData = data;
          this.rowData = data;
          // Fetch truck types
          this.truckTypeService.getAllTruckType().subscribe({
            next: (data) => {
              this.rowTruckData = data;
              this.truckTypeData = data;
              resolve();  // Resolve the promise once both are loaded
            },
            error: () => reject()
          });
        },
        error: () => reject()
      });
    });
  }

  // resetForm(): void {
  //   this.truckForm.reset();
  //   this.isEditMode = false;
  //   this.fetchTruckersAndTypes()
  //   this.loadAllBusinessUnitAndTruckOps()
  // }

  // loadTruckOperationSiteTagging(truckId: string): void {
  //   this.truckOperationSiteTagging.getBusinessUnitAndTruckOpsLinq(truckId).subscribe({
  //     next: (data) => {
  //       this.dataFromTrucktaggingAndTruck = data;
  //       console.log('Truck tagging data:', data);
  //     }
  //   });
  // }

  // loadAllBusinessUnitAndTruckOps(): void {
  //   this.truckOperationSiteTagging.getBusinessUnitAndTruckOps().subscribe({
  //     next: (data) => {
  //       this.dataFromBusinessUnit = data;
  //       console.log('All business units and truck ops:', data);
  //     }
  //   });
  // }



  onCellValueChanged(event: any): void {
    // console.log('Cell Value Changed Event:', event.data);

    // Get the updated row data
    const updatedData = event.data;
    // Find the index of the row in dataFromtruckTagging based on the unique identifier (id)
    const index = this.dataFromtruckTagging.findIndex(row => row.businessUnitId === updatedData.businessUnitId);

    if (index !== -1) {
      // If found, update the existing data
      this.dataFromtruckTagging[index] = {
        ...this.dataFromtruckTagging[index],
        ...updatedData
      };

    } else {
      this.dataFromtruckTagging.push(updatedData);
    }

  }

  saveTruck() {
    const missingFields = [];
    const truckerId = this.truckForm.get('truckerId')?.value;
    const truckTypeId = this.truckForm.get('truckTypeId')?.value;
    const isNullplateno = this.truckForm.get('plateNo')?.value;
    const isNullowner = this.truckForm.get('ownershipTypeId')?.value;
    const isNullAveKm = this.truckForm.get('aveKmperLiter')?.value;
    const isNullLockin = this.truckForm.get('isLockin')?.value;
    const jwt = this.tokenService.getJwt();
    if (!truckerId || !truckTypeId || !isNullplateno || !isNullLockin || !isNullowner || !isNullAveKm) {
      if (!truckerId) missingFields.push("Trucker ID");
      if (!truckTypeId) missingFields.push("Truck Type");
      if (!isNullplateno) missingFields.push("Plate No");
      if (!isNullLockin) missingFields.push("Lock-in Status");
      if (!isNullowner) missingFields.push("OwnerShip");
      if (!isNullAveKm) missingFields.push("aveKmperLiter");
      this.isNotif = true
      this.updateNotification(
        `Please fill out the following field/s: ${missingFields.join(", ")}, Thank you.`,
        "Failed",
        "danger"
      );
      window.scroll(0, 0);
      return;
    }

    if (!jwt) {
      this.isNotif = true
      this.updateNotification("Please contact developers", 'Failed', 'danger');
      return;
    }
    const createdUserById = this.access?.[0]?.user || null;
    const currentDate = new Date();
    if (this.selectedTruckId) {
      // Update mode
      this.truckForm.patchValue({
        lastUpdatedDate: currentDate,
        lastUpdatedByUserId: createdUserById
      });
      const truckData = this.truckForm.value;
      const operationSiteTaggings = this.dataFromtruckTagging;

      const combinedTruckData = {
        ...truckData,
        truckOperationSiteTaggings: operationSiteTaggings.map(tag => ({
          businessUnitId: tag.businessUnitId,
          createdDate: new Date().toISOString(),
          lastUpdatedDate: new Date().toISOString(),
          remarks: tag.remarks,
          isDedicated: tag.isDedicated,
          status: tag.status,
          id: tag.id
        }))
      };
      this.truckService.updateTruck(this.selectedTruckId, combinedTruckData).subscribe({
        next: () => {
          this.isNotif = true
          this.updateNotification('Updated Truck, Thank you', 'Successfully', 'success');
          this.dataFromtruckTagging = []
          window.scroll(0, 0);
        },
        error: (error) => {
          this.isNotif = true
          this.updateNotification('Failed to update the truck', 'Error', 'danger');
        },
      });

    } else {
      // Add new truck mode

      this.truckForm.patchValue({
        createdDate: currentDate,
        lastUpdatedDate: currentDate,
        createdByUserId: createdUserById,
      });
      const formValue = { ...this.truckForm.value }; // Create a shallow copy
      delete formValue.lastUpdatedByUserId; // Remove `lastUpdatedByUserId`
      const truckData = this.truckForm.value;
      const operationSiteTaggings = this.dataFromtruckTagging;

      const combinedTruckData = {
        ...formValue,
        truckOperationSiteTaggings: operationSiteTaggings.map(tag => ({
          businessUnitId: tag.businessUnitId,
          createdDate: new Date().toISOString(),
          lastUpdatedDate: new Date().toISOString(),
          remarks: tag.remarks,
          isDedicated: tag.isDedicated,
          status: tag.status
        }))
      };

      this.truckService.addTruck(combinedTruckData).subscribe({
        next: (data) => {
          this.isNotif = true
          this.updateNotification('Added New Truck, Thank you', 'Successfully', 'success');
          window.scroll(0, 0);
        },
        error: (error) => {
          this.isNotif = true
          window.scroll(0, 0);
          this.updateNotification('Failed to add the truck', 'Error', 'danger');
        },
      });
    }
  }

  //START MODAL FOR TruckType
  openTruckModal() {
    const modalElement = document.getElementById('truckTypeModal');
    const modalInstance = new bootstrap.Modal(modalElement);
    modalInstance.show();
  }

  closeTruckModal() {
    const modalElement = document.getElementById('truckTypeModal');
    const modalInstance = bootstrap.Modal.getInstance(modalElement);
    modalInstance.hide();
  }

  rowTruckClicked(event: any): void {
    this.clickedRowData = event.data;
    this.truckForm.get('truckTypeId')?.setValue(event.data.id);
    this.truckForm.get('truckTypeName')?.setValue(event.data.type);
    this.closeTruckModal();
  }
  //END MODAL FOR TruckType

  //START MODAL FOR Trucker Name
  openModal() {
    const modalElement = document.getElementById('truckerModal');
    const modalInstance = new bootstrap.Modal(modalElement);
    modalInstance.show();

  }

  closeModal() {
    const modalElement = document.getElementById('truckerModal');
    const modalInstance = bootstrap.Modal.getInstance(modalElement);
    modalInstance.hide();
  }

  rowClicked(event: any): void {
    this.clickedRowData = event.data;
    this.truckForm.get('truckerId')?.setValue(event.data.id);
    this.truckForm.get('truckerName')?.setValue(event.data.name);
    this.closeModal();
  }
  //START MODAL FOR Trucker Name

  onRightClick(event: MouseEvent) {
    event.preventDefault();
    this.contextMenuPosition = {
      x: `${event.pageX}px`,
      y: `${event.pageY}px`
    };
    this.displayDropdown = true;
  }

  // Start form right click
  onRightCreateTruck() {
    this.toggleFormControls(false);
    this.selectedTruckId = null
    this.fieldDisabled = false
    this.dataFromTruckMovementHistory = []
    this.truckOperationSiteTagging.getBusinessUnitAndTruckOps().subscribe({
      next: (data) => {
        this.dataFromBusinessUnit = data
      }
    })
    this.truckForm.reset({
      plateNo: null,
      truckTypeId: null,
      truckTypeName: null,
      truckerId: null,
      truckerName: null,
      vanType: null,
      plateType: 0,
      year: null,
      make: null,
      body: null,
      weightCapacity: null,
      chassisNumber: null,
      engineNumber: null,
      companyName: null,
      ownershipTypeId: null,
      owner: null,
      crnumber: null,
      crDate: null,
      ornumber: null,
      orDate: null,
      acquisitionDate: null,
      AcquisitionCost: null,
      emissionResult: false,
      emissionDate: null,
      hasGps: false,
      gpsprovider: null,
      odoMeter: 0,
      fuelType: null,
      aveKMiliter: null,
      insurer: null,
      coverage: null,
      insuranceExpiryDate: null,
      policyNumber: null,
      status: true,
      deployment: null,
      garage: null,
      isforBilling: false,
      ltfrbExpirationDate: null,
      remarks: null,
    })
    this.isNotif = false
    this.displayDropdown = false;
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
  }

  onRightEditTruck() {
    this.displayDropdown = false;
    this.toggleFormControls(false);
    this.disableSubmit = false
    this.isEditMode = true;
    this.fieldDisabled = false
    this.isTruckOpsEdit = true;
    if (this.gridApi) {
      setTimeout(() => {
        this.gridApi?.refreshCells({ force: true });
      }, 0);
    }
  }

  onFindTruck() {
    this.currentTabIndex = 1
    this.notif = false
    this.displayDropdown = false;
  }

  // End form right click

  toggleFormControls(disable: boolean): void {
    Object.keys(this.truckForm.controls).forEach(controlName => {
      const control = this.truckForm.get(controlName);
      if (control) {
        if (disable) {
          control.disable({ onlySelf: true });
        } else {
          control.enable({ onlySelf: true });
        }
      }
    });
  }


  //Start List Right Click
  onListEditTruck(): void {
    if (this.selectedTruck && this.jwt != '') {
      this.disableSubmit = false

      // this.fieldDisabled = false
      this.isEditMode = true;
      const truckString = this.selectedTruck?.toString();
      this.selectedTruckId = truckString; // Assign the string `id` to the shared property
      this.fetchTruck(truckString);
      this.currentTabIndex = 0; // Switch to Tab 1
      this.toggleFormControls(false);
    }
    this.displayDropdown = false;
  }

  //End List Right Click

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    this.displayDropdown = false;
  }

  // START EMPLOYEE STATUS DROPDOWN
  selectTruckStatus(value: any): void {
    this.truckForm.get('status')?.setValue(value);
  }
  getTruckStatusLabel(): string {
    const statusValue = this.truckForm?.get('status')?.value;
    const selectedOption = this.truckStatusOptions?.find(option => option.value === statusValue);
    return selectedOption ? selectedOption.label : 'Select';
  }
  selectOwnerShip(value: any): void {
    this.truckForm.get('ownershipTypeId')?.setValue(value);
  }
  getOwnerShipTypeIdLabel(): string {
    const statusValue = this.truckForm?.get('ownershipTypeId')?.value;
    const selectedOption = this.ownerShipOptions?.find(option => option.id === statusValue);
    return selectedOption ? selectedOption.label : 'Select';
  }
  // START DATE FORMAT

  dateFormatter(params: any) {
    const date = params.value;
    if (!date) {
      return ''; // Display nothing if the date is null or undefined
    }
    return new Date(date).toLocaleDateString(); // Format the date if it's valid
  }
  // END DATE FORMAT

  showTagging() {
    this.showMovementHistoryTable = false
    this.showTaggingTable = true
    this.getData()
    this.getColumnDef()
  }

  showMovementHistory() {
    this.showMovementHistoryTable = true;
    this.showTaggingTable = false;
    this.fetchMovementHistory()
    // Retrieve the truckId from the form or fallback to idFromTruckList

    // Only make the service call if truckId is available
  }

  fetchMovementHistory(): void {
    // Ensure the JWT token is available
    const jwt = this.tokenService.getJwt();
    if (!jwt) {
      this.isNotif = true
      this.updateNotification('please contact the developers, thank you.', 'Failed', 'danger')
      return;
    }

    if (this.selectedTruckId) {
      // Make the API call for fetching truck movement history
      this.truckMovement
        .getAllTruckMovementHistoryWithTruckId(this.selectedTruckId, jwt)
        .subscribe({
          next: (data) => {
            this.dataFromTruckMovementHistory = data;
          },
          error: (err) => {
            this.isNotif = true
            this.updateNotification('please contact the developers, thank you.', 'Failed', 'danger')
          },
        });
    } else {
    }
  }


  // START DATE FORMAT
  // dateFormatter(params: any): string {
  //   const date = new Date(params.value);
  //   if (!isNaN(date.getTime())) {
  //     return date.toLocaleDateString();
  //   }
  //   return params.value;
  // }
  // END DATE FORMAT
  onSearch() {
    if (this.criteriaList.length === 0) {
      this.filteredRowData = [];
      return;
    }
    this.isLoading = true;
    this.searchTrucks();
  }

  searchTrucks() {
    this.truckService.searchTruck(this.criteriaList).subscribe({
      next: (data) => {
        this.filteredRowData = data;
        this.isLoading = false;
      },
      error: () => {
        this.isNotif = true
        this.updateNotification('please contact the developers, thank you.', 'Failed', 'danger')
        this.isLoading = false;
      },
    });
  }

  selectFilter(event: Event) {
    const target = event.target as HTMLSelectElement;
    this.selectedFilter = target.value;
  }

  getCriteriaLabel(criteria: TruckFilterCriteria): string {
    switch (criteria.selectedOption) {
      case 'plateNo':
        return `Plate No: ${criteria.plateNoFrom || ''} - ${criteria.plateNoTo || ''}`;
      case 'createdDate':
        return `Created Date: ${criteria.startDate ? criteria.startDate.toLocaleDateString() : ''} - ${criteria.endDate ? criteria.endDate.toLocaleDateString() : ''}`;
      case 'truckerName':
        return `Trucker Name: ${criteria.truckerNameFrom || ''} - ${criteria.truckerNameTo || ''}`;
      default:
        return '';
    }
  }

  // START SET FROM OR TO SAME VALUE IF ONE FIELD IS MISSING
  private setDefaultToValues(criteria: TruckFilterCriteria) {
    if (!criteria.plateNoTo) {
      criteria.plateNoTo = criteria.plateNoFrom;
    } else if (!criteria.plateNoFrom) {
      criteria.plateNoFrom = criteria.plateNoTo;
    }
    if (!criteria.truckerNameTo) {
      criteria.truckerNameTo = criteria.truckerNameFrom;
    } else if (!criteria.truckerNameFrom) {
      criteria.truckerNameFrom = criteria.truckerNameTo;
    }
    if (!criteria.endDate) {
      criteria.endDate = criteria.startDate;
    } else if (!criteria.startDate) {
      criteria.startDate = criteria.endDate;
    }
  }
  // END SET FROM OR TO SAME VALUE IF ONE FIELD IS MISSING

  // START ADD CRITERIA
  addCriteria() {
    const criteria: TruckFilterCriteria = this.searchForm.value;

    this.setDefaultToValues(criteria);
    // Convert to number if necessary
    criteria.truckerNameFrom = criteria.truckerNameFrom ? Number(criteria.truckerNameFrom) : null;
    criteria.truckerNameTo = criteria.truckerNameTo ? Number(criteria.truckerNameTo) : null;

    const existingIndex = this.criteriaList.findIndex(
      (item) => item.selectedOption === criteria.selectedOption
    );

    if (existingIndex !== -1) {
      this.criteriaList[existingIndex] = criteria;
    } else {
      this.criteriaList.push(criteria);
    }

    // Store updated criteria in local storage
    localStorage.setItem('criteriaList', JSON.stringify(this.criteriaList));
  }


  removeCriteria(index: number) {
    if (index < 0 || index >= this.criteriaList.length) {
      this.isNotif = true
      this.updateNotification('please contact the developers, thank you.', 'Failed', 'danger')
      return;
    }


    const removedCriterion = this.criteriaList[index]; // Get the removed criterion
    this.criteriaList.splice(index, 1); // Remove the criterion from the list

    // Reset form controls based on the removed criterion
    this.resetFormControls(removedCriterion.selectedOption);

    // Update local storage after removing a criterion
    // this.criteriaList = [];
    // this.filteredRowData = [];
    // localStorage.removeItem('criteriaList'); // Clear local storage
    // localStorage.setItem('criteriaList', JSON.stringify(this.criteriaList));

    // If necessary, you may want to trigger a search after removing a criterion
  }


  // removeCriteria(index: number) {
  //   if (index < 0 || index >= this.criteriaList.length) {
  //     console.error('Index out of bounds. Cannot remove criteria.');
  //     return;
  //   }

  //   const removedCriterion = this.criteriaList[index]; // Get the removed criterion
  //   this.criteriaList.splice(index, 1); // Remove the criterion from the list

  //   // Reset form controls based on the removed criterion
  //   this.resetFormControls(removedCriterion.selectedOption);

  //   // Update local storage after removing a criterion
  //   localStorage.setItem('criteriaList', JSON.stringify(this.criteriaList)); // Update local storage with the updated list

  //   // If necessary, trigger a search or update the filtered data after removing a criterion
  //   if (this.criteriaList.length === 0) {
  //     // If no criteria left, clear filtered data
  //     this.filteredRowData = [];
  //   } else {
  //     // Trigger a search or update the filtered data
  //     this.onSearch();
  //   }
  // }

  resetFormControls(selectedOption: string) {
    switch (selectedOption) {
      case 'plateNo':
        this.searchForm.patchValue({
          plateNoFrom: '',
          plateNoTo: '',
        });
        break;
      case 'truckerName':
        this.searchForm.patchValue({
          truckerNameFrom: '',
          truckerNameTo: '',
        });
        break;
      case 'createdDate':
        this.searchForm.patchValue({
          startDate: '',
          endDate: '',
        });
        break;
      default:
        break;
    }
  }

  // END REMOVE INPUTS WHEN CRITERIA IS REMOVED


  onRowDoubleClicked(event: RowDoubleClickedEvent<any>) {
    const rowData = event.data?.id; // Safely access row ID
    this.isTruckOpsEdit = false;
    if (this.gridApi) {
      setTimeout(() => {
        this.gridApi?.refreshCells({ force: false });
      }, 0);
    }
    if (this.userHasViewAccess && this.jwt !== '') {
      this.disableSubmit = true
      // this.fieldDisabled = true
      this.isNotif = false
      this.isEditMode = false;
      if (rowData) {
        this.selectedTruckId = rowData; // Assign to shared property
        this.fetchTruck(rowData);
        this.currentTabIndex = 0; // Switch to Tab 1
        this.toggleFormControls(true);
      } else {
        this.isNotif = true
        this.updateNotification("Sorry, you don't have permission to view the data", 'Failed to view', 'warning');
      }
    } else {
      this.isNotif = true
      this.updateNotification("Sorry, you don't have permission to view the data", 'Failed to view', 'warning');
    }
  }

  activateTab(tabId: string) {
    // Remove 'active' class from all tabs
    const tabs = document.querySelectorAll('.tab-pane');
    tabs.forEach(tab => tab.classList.remove('show', 'active'));

    // Add 'active' class to the target tab
    const targetTab = document.getElementById(tabId);
    if (targetTab) {
      targetTab.classList.add('show', 'active');
    } else {
      // console.error(`Tab with id "${tabId}" not found.`);
    }
  }


  onCellContextMenu(event: CellContextMenuEvent) {
    if (event && event.event) {
      this.selectedTruck = event.node.data.id;
      this.contextMenuPosition.x = `${(event.event as MouseEvent).clientX}px`;
      this.contextMenuPosition.y = `${(event.event as MouseEvent).clientY}px`;
      this.displayDropdown = true;
      event.event.preventDefault();
    }
  }

  resetCriteria() {
    this.criteriaList = [];
    this.filteredRowData = [];
    localStorage.removeItem('criteriaList'); // Clear local storage
  }
  @HostListener('window:beforeunload', ['$event'])
  clearLocalStorage(event: Event): void {
    localStorage.clear();  // Clears all localStorage data
  }
  onTabChange(index: number) {
    if (index === 0) {
      this.isNotif = false
    }
  }
}
